import React from 'react'
import ReactDOM from 'react-dom'

const App = () => {
  return (
    <>
      <LoanchModalWhenPageDisplay handleModalShow={ handleModalWhenPageDisplayShow } />
      <LoanchModalWhenPushButtonType1 handleModalShow={ handleModalWhenPushButtonType1 } />
      <LoanchModalWhenPushButtonType2 handleModalShow={ handleModalWhenPushButtonType2 } />
    </>
  );
}

$(() => {
  const elm = document.querySelector('#launchInitilCost');
  ReactDOM.render(
    <App />,
    elm
  );
});

// トップページ遷移時のトリガー
const LoanchModalWhenPageDisplay = ({ handleModalShow }) => (
  <button id='LoanchModalWhenPageDisplay' className='d-none btn btn-primary' type='button' onClick={ handleModalShow }></button>
);

// 就業規則作成 or プラン変更時のトリガー
const LoanchModalWhenPushButtonType1 = ({ handleModalShow }) => (
  <button id='LoanchModalWhenPushButtonType1' className='d-none btn btn-primary' type='button' onClick={ handleModalShow }></button>
);

// クラウド顧問機能操作時のトリガー
const LoanchModalWhenPushButtonType2 = ({ handleModalShow }) => (
  <button id='LoanchModalWhenPushButtonType2' className='d-none btn btn-primary' type='button' onClick={ handleModalShow }></button>
);
